
import Vue from 'vue'

export default Vue.extend({
  name: 'JuryProjectDetail',

  data: () => {
    return {
      iframeReady: false,
      isLoading: true,
      items: [] as any,
      project: {} as any,
    }
  },

  computed: {
    appInfo () {
      return this.$store.state.appInfo
    },
    baseUrl () {
      return this.$store.state.baseUrl
    },
    currentAward () {
      return this.$store.state.currentAward
    },
    currentJuryConf () {
      return this.$store.state.currentJuryConf
    },
    currentPhase () {
      return this.$store.state.currentJuryConf.phases?.filter((p: any) => p.key === this.phase)[0]
    },
    id () {
      return this.$route.params.id
    },
    next () {
      if (!this.items) return null
      let i = this.items.findIndex((p: any) => p.id === this.id) + 1
      if (i >= this.items.length) i = 0
      return this.items[i]?.id
    },
    pd () {
      return this.project?.projectDetails?.[0]
    },
    phase () {
      return this.$route.params.phase
    },
    prev () {
      if (!this.items) return null
      let i = this.items.findIndex((p: any) => p.id === this.id) - 1
      if (i < 0) i = this.items.length ? this.items.length - 1 : 0
      return this.items[i]?.id
    },
  },

  methods: {
    action (e: Event, action: any, ) {
      e.preventDefault()
      this.isLoading = true
      const data = {
        awardType: this.currentAward.awardType,
        year: this.currentAward.year,
        action: {
          key: action.key,
        } as any,
        phase: this.$route.params.phase,
        id: this.id,
      }
      if (action.type === 'comment') {
        const attributeName = action.data.split('.')[1]
        const input = this.$refs[attributeName] as HTMLInputElement[]
        data.action[attributeName] = input[0].value
      }
      this.$store.dispatch('postJuryAction', data).then(() => {
        this.initObject()
      })
    },

    check (action: any) {
      let res = ''
      let data = Object.keys(action.data)
      let dataVals = Object.values(action.data)
      const key = data[0].split('.')[1]
      if (key && this.pd && this.pd[key] && this.pd[key] == dataVals[0]) res = 'check'
      return res
    },

    initObject () {
      this.isLoading = true
      this.$store
        .dispatch('getProjectJury', {
          id: this.$route.params.id,
          awardType: this.currentAward.awardType,
          year: this.currentAward.year,
        })
        .then((p) => {
          if (p.projectDetails && this.currentAward) {
            const { awardType, year } = this.currentAward
            const pd = p.projectDetails.filter((o: any) => {
              return o.awardType == awardType && o.year == year
            })
            if (pd && pd[0]) {
              const pd0 = Object.entries(pd[0])
              pd0.forEach(o => {
                if (typeof o[1] !== 'undefined' && typeof p[o[0]] === 'undefined') {
                  p[o[0]] = o[1]
                }
              })
            }
          }
          this.project = p;
          this.isLoading = false
          let res: any[] = []
          this.$store.state.juryGroups.forEach((g: any) => {
            res = res.concat(g.items)
          })
          this.items = res
        })
    },

    scrollIframe (direction: any) {
      const iframe: any = this.$refs.iframe
      if (direction === 'up') {
        iframe.contentWindow.scrollTo(0, 0)
      } else {
        iframe.contentWindow.scrollTo(0, 9999)
      }
    },
  },

  created () {
    this.$store.commit('setFullscreenMode', true)
  },

  mounted () {
    this.$store.commit('setFullscreenMode', true)
    this.initObject()
  },

  destroyed () {
    this.$store.commit('setFullscreenMode', false)
  },

  watch: {
    id () {
      this.initObject()
    },

    phase (to) {
      if (to !== this.$route.params.phase) {
        this.$router.replace({name: 'jury-detail', params: {
          phase: this.phase,
          id: this.id,
        }})
        this.initObject()
      }
    },
  },
})
